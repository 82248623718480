




















  import { Component, mixins } from 'nuxt-property-decorator'

  import { namespace } from 'vuex-class'
  import { Categories, CMS, Plugins } from '@one/types'
  import SeoMethodsMixin, { DIVIDER } from '../mixins/SeoMethodsMixin'
  import Category = Categories.Category;
  import StaticPageDto = CMS.Responses.StaticPageDto
  import { ExtendedComponent } from '~/store/plugins'
  import IFrameShopPlugin = Plugins.IFrameShopPlugin

  const cms = namespace('cms')
  const categories = namespace('categories')
  const products = namespace('products')
  const plugins = namespace('plugins')
  const routeStore = namespace('route')

  @Component({
    scrollToTop: true,
    middleware: ['isAuthenticatedOrPlatformOpened'],
    watchQuery: ['filtersQuery', 'page', 'rows', 'sortCriteria', 'price'],
    transition(_to: any, from: any): string {
      if (from && (from.name.includes('search') || from.name === 'all')) {
        return 'no-transition'
      }
      return 'fade'
    },
    components: {
      OneCatalogViewList: () => import('~/components/organisms/list/OneCatalogViewList.vue'),
      OneCmsGridRender: () => import('~/components/molecules/renders/OneCmsGridRender.vue'),
      OneExternalIframePlugin: () => import('~/components/molecules/plugins/OneExternalIframePlugin.vue'),
      Error: () => import('~/layouts/error.vue'),
    },
  })
  export default class GenericPage extends mixins<SeoMethodsMixin>(SeoMethodsMixin) {
    name: string = 'generic-page'

    @routeStore.State path: any;
    @cms.State(state => state.staticPages) staticPages!: Record<string, StaticPageDto>;
    @cms.Getter getPage: any;
    @categories.Getter getCategoryByUrl: any;
    @categories.Getter getSelectedCategory: any
    @categories.Getter getCategory: any
    @categories.State selectedCategoriesTree: any
    @products.Mutation('CLEAR_SELECTED_FILTERS') clearSelectedFilters: any;
    @plugins.Getter getRoutableComponentBySlug: any;

    notFoundError: any = {
      statusCode: 404,
      err: {},
    }

    availableComponents: any = {
      html: () => import('~/components/organisms/grid/shared/OneCmsDynamicComponent.vue'),
      staticHtml: () => import('~/components/organisms/grid/shared/OneCmsStaticComponent.vue'),
      image: () => import('~/components/molecules/images/OneCmsGridImageComponent.vue'),
      link: () => import('~/components/molecules/links/OneCmsGridLinkComponent.vue'),
      productContainer: () => import('~/components/organisms/grid/content/OneCmsProductContainer.vue'),
      productsListing: () => import('~/components/organisms/grid/static/OneProductsListing.vue'),
      Category: () => import('~/components/organisms/grid/content/OneCmsCategoryOverview.vue'),
      TopProducts: () => import('~/components/organisms/grid/content/OneCmsTopProducts.vue'),
      StaticBanners: () => import('~/components/organisms/grid/content/OneCmsStaticBanners.vue'),
      carouselWithCategories: () => import('~/components/organisms/grid/content/OneCmsCategoriesListWithBanner.vue'),
      customizableProductsRotator: () => import('~/components/organisms/grid/content/OneCmsProductsRotator.vue'),
      carouselWithThumbnails: () => import('~/components/organisms/grid/content/OneCmsCarouselWithThumbnails.vue'),
      youtube: () => import('~/components/organisms/grid/shared/OneCmsYoutubeComponent.vue'),
      textEditor: () => import('~/components/organisms/grid/shared/OneCmsTextEditorComponent.vue'),
      codesScanner: () => import('~/components/organisms/shared/OneBarcodeScanner.vue'),
      gridArticle: () => import('~/components/organisms/grid/content/OneCmsArticle.vue'),
    }

    mainStaticPageClass = 'one-static-page'
    mainCatalogPageClass = 'one-catalog-page'
    mainPluginPageClass = 'one-external-plugin-page'

    head() {
      return {
        title: this.structuredSeoTitle(DIVIDER.DOT, [this.filtersSeo, this.titleSeoPage]),
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.structuredSeoTitle(DIVIDER.DASH, this.descriptionSeoPage),
          },
          {
            hid: 'robots',
            name: 'robots',
            content: this.pageRobotsValue,
          },
        ],
      }
    }

    get page() {
      return this.getPage(this.path)
    }

    get staticPage(): StaticPageDto {
      return this.staticPages[this.path.substring(1)]
    }

    get pluginComponent(): ExtendedComponent<IFrameShopPlugin> {
      return this.getRoutableComponentBySlug(this.path.substring(1))
    }

    get titleSeoPage(): string | null {
      if (this.pluginComponent) { return this.pluginComponent.raw.page.title }
      if (this.staticPage) { return this.staticPage.seo.title }
      if (this.page) { return this.page.title }
      if (this.isCategoryView) {
        return this.getSelectedCategory?.seo?.title || this.getSelectedCategory?.name || null
      }
      return null
    }

    get hiddenH1Value() {
      if (this.staticPage) { return this.staticPage.seo.h1 }
      if (this.page) { return this.page.title }
    }

    get descriptionSeoPage(): Array<string> {
      if (this.staticPage) { return [this.staticPage.seo.description] }
      if (this.titleSeoPage) {
        const categoryDescription = this.getSelectedCategory?.seo?.description
        const defaultDescription = !categoryDescription && [this.defaultSeoData.description]
        return [
          this.structuredSeoTitle(DIVIDER.DASH, [categoryDescription || this.titleSeoPage, this.filtersSeo, defaultDescription]),
        ].filter(Boolean)
      } else {
        return [this.defaultSeoData.description]
      }
    }

    get pageRobotsValue(): string {
      if (this.staticPage) { return this.staticPage.seo.robots }
      return this.categoryRobotsSeo
    }

    get categoryRobotsSeo(): string {
      const categoryRobots = this.robotsSeo(this.getSelectedCategory?.seo)
      return this.isCategoryView && categoryRobots.length >= 2 ? categoryRobots : this.defaultSeoRobots
    }

    get categoryKeywordsSeo(): string {
      return this.selectedCategoriesTree.length > 0
        ? this.selectedCategoriesTree.map((x: string) => this.getCategory(x).name).reverse().join(', ')
        : this.titleSeoPage
    }

    get isCategoryView(): boolean {
      return this.getCategoryByUrl(this.path)
    }

    get listingTitle(): string | undefined {
      const selectedCategory = this.getSelectedCategory && this.getSelectedCategory.name
      return selectedCategory && this.isCategoryView ? `${selectedCategory}` : undefined
    }

    destroyed() {
      if (this.isCategoryView) {
        this.$plugins.onUnmounted(this.mainCatalogPageClass)
      } else if (this.page) {
        this.$plugins.onUnmounted(this.mainStaticPageClass)
      } else if (this.pluginComponent) {
        this.$plugins.onUnmounted(this.mainPluginPageClass)
      }
    }

    mounted() {
      if (process.client && this.page) {
        // 0.65 ms - 0.80ms
        const pageElement: any = this.$refs.staticPage
        const setListener = (anchor: HTMLAnchorElement, callback: EventListener) => {
          anchor.addEventListener('click', callback)
          this.$once('hook:beforeDestroy', () => {
            anchor.removeEventListener('click', callback)
          })
        }
        const setManufacturerAnchorListeners = () => {
          pageElement.querySelectorAll('a[data-manufacturer]').forEach((anchor: HTMLAnchorElement) => {
            if (anchor.dataset.manufacturer) {
              setListener(anchor, (e: any) => {
                e.preventDefault()
                this.clearSelectedFilters()
                this.$router.push(this.$routes.getManufacturerSearchUrl(anchor.dataset.manufacturer!))
              })
            }
          })
        }
        const setProductAnchorListeners = () => {
          pageElement.querySelectorAll('a[data-product]').forEach((anchor: HTMLAnchorElement) => {
            if (anchor.dataset.product) {
              setListener(anchor, (e: any) => {
                e.preventDefault()
                this.$router.push(this.$routes.getProductPathById(anchor.dataset.product!) as string)
              })
            }
          })
        }
        const setCategoryAnchorListeners = () => {
          pageElement.querySelectorAll('a[data-category]').forEach((anchor: HTMLAnchorElement) => {
            if (anchor.dataset.category) {
              setListener(anchor, (e: any) => {
                e.preventDefault()
                const path: string | null = this.$routes.getCategoryPath(anchor.dataset.category)
                if (path) {
                  this.$router.push(path)
                }
              })
            }
          })
        }
        const setStaticPagesAnchorListeners = () => {
          pageElement.querySelectorAll('a[data-page]').forEach((anchor: HTMLAnchorElement) => {
            if (anchor.dataset.page) {
              setListener(anchor, (e: any) => {
                e.preventDefault()
                this.$router.push(anchor.dataset.page!)
              })
            }
          })
        }
        const setRelativeAnchorListeners = () => {
          pageElement.querySelectorAll(
            'a:not([data-page]):not([data-manufacturer]):not([data-product]):not([data-category])',
          ).forEach((anchor: HTMLAnchorElement) => {
            setListener(anchor, (e: any) => {
              const tenantHostname: string = window.location.hostname
              if (tenantHostname === anchor.hostname) {
                e.preventDefault()
                const origin = window.location.origin
                const relativePath = anchor.href.substring(anchor.href.indexOf(origin) + origin.length)
                this.$router.push(relativePath)
              }
            })
          })
        }
        setManufacturerAnchorListeners()
        setProductAnchorListeners()
        setCategoryAnchorListeners()
        setStaticPagesAnchorListeners()
        setRelativeAnchorListeners()
      }
      if (this.isCategoryView) {
        this.$plugins.onMounted(this.mainCatalogPageClass, this)
      } else if (this.page) {
        this.$plugins.onMounted(this.mainStaticPageClass, this)
      } else if (this.pluginComponent) {
        this.$plugins.onMounted(this.mainPluginPageClass, this)
      }
    }

    async fetch({
      app, store, route, redirect, error,
    }: any) {
      const handleGridStaticPage = () => {
        store.commit('layout/TOGGLE_BREADCRUMBS', false)
      }
      const handleStaticPage = async (staticPage: any) => {
        store.commit('layout/TOGGLE_BREADCRUMBS', false)
        if (!staticPage.content) {
          await store.dispatch('cms/fetchStaticPage', staticPage.url)
        }
        return Promise.resolve()
      }
      const handleCatalogPage = async (category: Category) => {
        const currentPriceType: string = store.getters['layout/priceType']
        const setCatalogLoading = () => {
          if (process.client) {
            app.wait.start('catalog-loading')
          }
        }
        const clearCatalogLoading = () => {
          if (process.client) {
            setTimeout(() => {
              app.wait.end('catalog-loading')
            }, 250)
          }
        }
        const addedPriceParam = (price: string | null) => {
          if (!(app.$utils.isAnonymousAndPurchaseDisabled || app.$utils.isB2BAndPlatformIsNotOpened) || !price) {
            store.commit('products/SET_PRICE', price)
          }
        }
        const redirectDeletePrice = () => {
          const { query } = route
          delete query.price
          addedPriceParam(null)
          return redirect(301, route.path, query)
        }
        const validPrice = () => {
          const { price } = route.query
          if (price) {
            const parsedPrice: [number, number] | null = app.$routes.parsePriceString(price)
            if (!parsedPrice) {
              addedPriceParam(null)
              return redirectDeletePrice()
            }
            const [min, max] = parsedPrice
            if (min <= max && price.includes(currentPriceType)) {
              return addedPriceParam(price)
            }
            return redirectDeletePrice()
          }
          addedPriceParam(null)
          return null
        }
        const fetchCatalogData = async () => {
          try {
            setCatalogLoading()
            const res: { ids: Array<string>, categorySelected: boolean } = await store.dispatch('products/fetchProducts', route.query)
            await store.dispatch('products/fetchFilters', route.query)
            if (res.categorySelected) {
              if (app.$utils.isPurchaseEnabledOrAuthenticated) {
                await Promise.all([
                  store.dispatch('stocks/fetchStocksForProductsAndWarehouses', {
                    skus: res.ids,
                    warehouses: store.state.stocks.warehouses.allIds,
                  }),
                  store.dispatch('products/fetchPricesForProducts', res.ids),
                ])
              }
            }
            clearCatalogLoading()
          } catch (error) {
            app.$logger.warn(error)
          }
        }
        const defaultRows = 15
        const defaultPage = 1
        const defaultSortCriteria = store.getters['products/getDefaultSortForCatalogBrowse']
        validPrice()
        await store.dispatch('categories/selectCategory', category.id)
        store.commit('layout/TOGGLE_BREADCRUMBS', true)
        store.commit('layout/SET_BREADCRUMBS', app.$routes.getCategoryBreadcrumbs())
        store.commit('products/SET_PAGE_SIZE', Number(route.query.rows || defaultRows))
        store.commit('products/SET_PAGE_NUMBER', Number(route.query.page || defaultPage))
        store.commit('products/SET_SORT_CRITERIA', route.query.sortCriteria || defaultSortCriteria)
        if (process.client) {
          fetchCatalogData()
        } else {
          await fetchCatalogData()
        }
        return Promise.resolve()
      }
      try {
        if (route.query.q) {
          return redirect(301, '/search', route.query)
        }
        const pluginComponent: ExtendedComponent<IFrameShopPlugin> | null = store.getters['plugins/getRoutableComponentBySlug'](route.path)
        if (pluginComponent) {
          return handleGridStaticPage()
        }
        const staticPage: any | undefined = store.getters['cms/getPage'](route.path)
        if (staticPage) {
          return handleStaticPage(staticPage)
        }
        const category: Category | null = store.getters['categories/getCategoryByUrl'](route.path)
        if (category) {
          return handleCatalogPage(category)
        }
        const gridStaticPage = route.path.substring(1) ? await store.dispatch('cms/fetchStaticPageBySlug', route.path.substring(1)) : null
        if (gridStaticPage) {
          return handleGridStaticPage()
        }
        return store.commit('layout/TOGGLE_BREADCRUMBS', false)
      } catch (err: any) {
        return error({
          statusCode: err.response?.status,
          message: err.response?.data,
          err: err,
        })
      }
    }
  }
